:root {
  --main-website: #090088;
  --text-4: #cdd5e0;
  --website-black: #000;
  --website-blue: #4c65fd;
  --website-yellow: #ffd43e;
}

html {
  scroll-behavior: smooth;
  max-width: 100%;
  margin: 0 auto !important;
}

body {
  max-width: 1440px;
  margin: 0 auto;
  font-family: Nunito, Helvetica;
}

section {
  background-color: #fafcfe;
  margin: 40px 0;
  font-family: Nunito, Helvetica;
}

.bb-header .frame-wrapper {
  background-color: #010048;
  width: 100%;
  height: 42px;
}

.bb-header .banner {
  justify-content: space-evenly;
  margin: auto;
  padding-top: 7px;
  display: flex;
}

.bb-header .frame-2 {
  flex: none;
  align-items: center;
  gap: 4px;
  display: inline-flex;
}

.bb-header .instance-node {
  width: 24px !important;
  height: 24px !important;
}

.bb-header .text-wrapper {
  color: #fff;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
}

.bb-header .frame-3 {
  align-items: center;
  height: 54px;
}

.bb-header .frame-4 {
  flex: none;
  align-items: center;
  display: inline-flex;
}

.bb-header .mask-group, .bb-footer .mask-group {
  width: 87px;
  height: 53.03px;
}

.bb-header .frame-5 {
  flex: none;
  align-items: flex-start;
  gap: 48px;
  display: inline-flex;
}

.bb-header .frame-6 {
  flex: none;
  align-items: center;
  gap: 2px;
  display: inline-flex;
}

.bb-header .frame-6 a {
  text-decoration: none;
}

.bb-header .text-wrapper-2 {
  color: #12222c;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}

.bb-header .frame-7 {
  flex: none;
  align-items: flex-start;
  display: inline-flex;
}

.bb-header .div-wrapper {
  border: 1px solid;
  border-color: var(--text-4);
  background-color: #fff;
  border-radius: 9px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  width: 179px;
  height: 46px;
  padding: 16px 8px 16px 24px;
  display: flex;
}

.bb-header .frame-8 {
  flex: none;
  align-items: center;
  gap: 12px;
  margin-top: -5px;
  margin-bottom: -5px;
  display: inline-flex;
}

.bb-header .search-input {
  color: #12222c;
  letter-spacing: 0;
  border: 0;
  width: 115px;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}

.bb-header .frame-9 {
  flex: none;
  display: inline-flex;
}

.bb-header .frame-10 {
  background-color: #fff;
  border-radius: 52px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 4px;
  height: 46px;
  padding: 17px 24px;
  display: inline-flex;
}

.bb-header .text-wrapper-4 {
  color: #160ac1;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -6px;
  margin-bottom: -4px;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}

.bb-header .frame-11 {
  background-color: #160ac1;
  border: 1px solid #160ac1;
  border-radius: 52px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 4px;
  height: 46px;
  padding: 17px 24px;
  display: inline-flex;
}

.bb-header .text-wrapper-5 {
  color: #fff;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -6px;
  margin-bottom: -4px;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}

.bb-header .text-wrapper-6 {
  color: #081f58;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -6px;
  margin-bottom: -4px;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}

.bb-header.get-quote {
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  height: 149px;
  display: inline-flex;
}

.bb-header.sign-in {
  width: 100%;
}

.bb-header .nav-link.active {
  color: #160ac2;
  text-transform: uppercase;
  border-bottom: 3px solid;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.bb-header.get-quote .frame-3 {
  gap: 130px;
  display: inline-flex;
}

.bb-header.sign-in .frame-3 {
  justify-content: space-around;
  margin: 10px 0;
  display: flex;
  top: 66px;
  left: 110px;
}

.bb-header.get-quote .frame-4 {
  gap: 195px;
}

.bb-header.sign-in .frame-4 {
  gap: 275px;
}

.bb-header.get-quote .frame-7 {
  gap: 16px;
}

.bb-header.sign-in .frame-7 {
  gap: 24px;
}

.bb-header.get-quote .frame-9 {
  align-items: flex-start;
}

.bb-header.sign-in .frame-9 {
  background-color: #fff;
  border: 1px solid #160ac1;
  border-radius: 52px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  height: 46px;
  padding: 17px 24px;
}

.input-group-text {
  background-color: #fff;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-left: 0;
}

.property-years-wrapper {
  background-color: #fff;
  width: 228px;
  height: 219px;
  box-shadow: 0 17px 12px #00000021;
}

.property-years-wrapper .frame-14 {
  flex-direction: column;
  gap: 2px;
  display: inline-flex;
  position: relative;
}

.property-years-wrapper .element {
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.property-years-wrapper .years-experience {
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.property-years-wrapper .years {
  align-items: flex-start;
  top: 26px;
  left: 31px;
}

.property-years-wrapper .since {
  align-items: center;
  top: 53px;
  left: 27px;
}

.property-years-wrapper .frame-14.years .element {
  color: var(--main-website);
  letter-spacing: 0;
  font-family: Raleway, Helvetica;
  font-size: 110px;
  font-weight: 700;
  line-height: normal;
}

.property-years-wrapper .frame-14.since .element {
  color: #5f6e89;
  letter-spacing: 2.88px;
  font-family: Nunito, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
}

.property-years-wrapper .frame-14.years .years-experience {
  color: #5f6e89;
  white-space: nowrap;
  font-family: Nunito, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
}

.property-years-wrapper .frame-14.since .years-experience {
  color: var(--main-website);
  font-family: Inter, Helvetica;
  font-size: 70px;
  font-weight: 700;
  line-height: normal;
}

.property-check-wrapper {
  justify-content: space-evenly;
  gap: 86px;
  display: flex;
}

.property-check-wrapper .overlap-group-wrapper {
  background-position: 50%;
  background-size: cover;
  width: 489px;
  height: 618px;
  position: relative;
}

.property-check-wrapper .overlap-group-2 {
  position: relative;
}

.property-check-wrapper .image {
  object-fit: cover;
  width: 489px;
  height: 617px;
  position: relative;
  top: 0;
  left: 0;
}

.property-check-wrapper .component-1 {
  bottom: 0;
  right: 0;
  position: absolute !important;
}

.property-check-wrapper .frame-15 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 48px;
  display: inline-flex;
  position: relative;
}

.property-check-wrapper .frame-16 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
  position: relative;
}

.property-check-wrapper .p {
  color: #12222c;
  letter-spacing: 0;
  width: 499px;
  margin-top: -1px;
  font-family: Raleway, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  position: relative;
}

.property-check-wrapper .text-wrapper-8 {
  color: #081f58;
  letter-spacing: 0;
  width: 533px;
  font-family: Nunito, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  position: relative;
}

.property-check-wrapper .features-wrapper {
  gap: 28px;
  position: relative;
}

.property-check-wrapper .frame-18 {
  gap: 16px;
  position: relative;
}

.property-check-wrapper .frame-19 {
  position: relative;
}

.property-check-wrapper .approve-tick {
  width: 24px !important;
  height: 24px !important;
  top: 3px !important;
  left: 3px !important;
}

.property-check-wrapper .text-wrapper-9 {
  color: #12222c;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Raleway, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
  position: relative;
}

.property-check-wrapper .frame-20 {
  flex: none;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.property-check-wrapper .frame-21 {
  position: relative;
}

.property-check-wrapper .text-wrapper-10 {
  color: #12222c;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Raleway, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
  position: relative;
}

.property-check-wrapper .frame-22 {
  flex: none;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.property-check-wrapper .frame-23 {
  position: relative;
}

.property-check-wrapper .text-wrapper-11 {
  color: #12222c;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Raleway, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
  position: relative;
}

.property-check-wrapper .frame-24 {
  flex: none;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.property-check-wrapper .frame-25 {
  position: relative;
}

.property-check-wrapper .text-wrapper-12 {
  color: #12222c;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Raleway, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
  position: relative;
}

.property-check-wrapper .check-marks {
  background-image: url("frame-91.16ee7678.svg");
}

.property-check-wrapper .bullet {
  background-image: url("frame-91-1.fd1e697a.svg");
}

.property-check-wrapper .property-1-check-marks {
  align-items: flex-end;
}

.property-check-wrapper .property-1-bullet {
  align-items: center;
  margin: 20px 5px;
  display: flex;
}

.property-check-wrapper .property-1-2-check-marks {
  align-items: flex-end;
}

.property-check-wrapper .property-1-2-bullet {
  align-items: center;
}

.property-check-wrapper .property-1-5-check-marks {
  align-items: flex-end;
}

.property-check-wrapper .property-1-5-bullet {
  align-items: center;
}

.property-check-wrapper .property-1-8-check-marks {
  align-items: flex-end;
}

.property-check-wrapper .property-1-8-bullet {
  align-items: center;
}

.property-check-wrapper .frame-18.property-1-check-marks .frame-19 {
  background-color: #12222c;
  width: 30px;
  height: 30px;
}

.property-check-wrapper .frame-18.property-1-bullet .frame-19 {
  background-color: #090088;
  border-radius: 8px;
  width: 16px;
  height: 16px;
}

.property-check-wrapper .frame-18.property-1-bullet .text-wrapper-9 {
  margin-top: -1px;
}

.property-check-wrapper .frame-20.property-1-2-check-marks .frame-21 {
  background-color: #12222c;
  width: 30px;
  height: 30px;
}

.property-check-wrapper .frame-20.property-1-2-bullet .frame-21 {
  background-color: #090088;
  border-radius: 8px;
  width: 16px;
  height: 16px;
}

.property-check-wrapper .frame-20.property-1-2-bullet .text-wrapper-10 {
  margin-top: -1px;
}

.property-check-wrapper .frame-22.property-1-5-check-marks .frame-23 {
  background-color: #12222c;
  width: 30px;
  height: 30px;
}

.property-check-wrapper .frame-22.property-1-5-bullet .frame-23 {
  background-color: #090088;
  border-radius: 8px;
  width: 16px;
  height: 16px;
}

.property-check-wrapper .frame-22.property-1-5-bullet .text-wrapper-11 {
  margin-top: -1px;
}

.property-check-wrapper .frame-24.property-1-8-check-marks .frame-25 {
  background-color: #12222c;
  width: 30px;
  height: 30px;
}

.property-check-wrapper .frame-24.property-1-8-bullet .frame-25 {
  background-color: #090088;
  border-radius: 8px;
  width: 16px;
  height: 16px;
}

.property-check-wrapper .frame-24.property-1-8-bullet .text-wrapper-12 {
  margin-top: -1px;
}

.company-section-wrapper {
  background-color: #fff;
  border-radius: 16px;
  height: 566px;
  overflow: hidden;
}

.company-section-wrapper .overlap-group {
  background-size: cover;
  height: 100%;
}

.company-section-wrapper .blog-card {
  border-radius: 16px;
  width: 393px;
  height: 231px;
  position: relative;
  top: 187px;
  left: 72px;
}

.company-section-wrapper .text-box-outer {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
  top: 32px;
}

.company-section-wrapper .products-services {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  text-shadow: 0 4px 12px #0000001f;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Raleway, Helvetica;
  font-size: 30px;
  font-weight: 600;
  line-height: 48px;
  position: relative;
}

.company-section-wrapper .company-button {
  background-color: #160ac1;
  border: 0;
  border-radius: 52px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 160px;
  height: 46px;
  padding: 17px 24px;
  display: flex;
  position: relative;
}

.company-section-wrapper .company-button-text {
  color: #fff;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -6px;
  margin-bottom: -4px;
  font-family: Nunito, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.company-section-wrapper .blog-card-bg {
  background-color: #00000070;
}

.company-section-wrapper .blog-card-transperency {
  -webkit-backdrop-filter: blur(18px) brightness();
  backdrop-filter: blur(18px) brightness();
  background-blend-mode: soft-light;
  background: linear-gradient(#494949 0%, #0000007a 100%);
  border: 1.5px solid #0000;
  border-image: radial-gradient(#ffc100, #ffc10000) 1;
  box-shadow: 2px 4px 30px #00000014;
}

.btn {
  --bs-btn-border-radius: 50px;
  --bs-btn-padding-x: 3rem;
}

.btn-primary {
  --bs-btn-bg: #160ac2;
  --bs-btn-border-color: #160ac2;
}

.btn-outline-primary {
  --bs-btn-border-color: #160ac2;
  --bs-btn-hover-bg: #160ac2;
  --bs-btn-color: #160ac2;
  --bs-btn-hover-border-color: #160ac2;
}

.pointer {
  cursor: pointer;
}

.df-center {
  justify-content: center;
  display: flex !important;
}

.landing-page-image {
  height: 340px;
}

.landing-page-header {
  text-shadow: 2px 2px 4px #000;
  margin-left: -77px;
  font-size: 48px;
  top: 50%;
  left: 50%;
}

.frame {
  background-color: #fafcfe;
}

.frame .component-3 {
  margin: auto;
  top: 144px !important;
  left: 64px !important;
}

.frame .component-2 {
  justify-content: center;
  margin-bottom: 150px;
  display: flex;
}

.frame .component-instance {
  background-image: url("frame-91-2.6bb4fcd3.svg") !important;
}

.frame .overlap {
  width: 100%;
  top: 3655px;
  left: 0;
}

.frame .frame-wrapper-2 {
  text-align: center;
  background-color: #2e2e2e;
  left: 0;
}

.frame-wrapper-2 .div-4 {
  justify-content: space-around;
  margin: auto;
  display: flex;
}

.frame .div-4 {
  height: 522px;
  padding-top: 40px;
  top: 236px;
  left: 154px;
}

.frame .div-5 {
  flex-direction: column;
  align-items: flex-start;
  gap: 56px;
  display: inline-flex;
  top: 47px;
  left: 0;
}

.frame .div-6 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 30px;
  display: inline-flex;
}

.frame .img {
  height: 53.03px;
}

.frame .text-wrapper-13 {
  color: #fff;
  letter-spacing: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
}

.frame .div-7 {
  flex: none;
  align-items: flex-start;
  gap: 48px;
  display: inline-flex;
}

.frame .div-8 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
}

.frame .text-wrapper-14 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
}

.frame .text-wrapper-15 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
}

.frame .div-9 {
  background-color: #1c1c1c;
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 56px 0 40px 24px;
  display: flex;
  top: 0;
  left: 648px;
}

.frame .div-10 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 40px;
  display: inline-flex;
}

.frame .get-a-quote-and-make {
  color: #fff;
  letter-spacing: 0;
  margin-top: 40px;
  font-size: 36px;
  font-weight: 700;
  line-height: 36px;
}

.frame .div-11 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
}

.frame .div-wrapper-2 {
  border: 1px solid #fff;
  border-radius: 8px;
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 10px 10px 10px 18px;
  display: flex;
}

.frame .div-12 {
  flex: none;
  align-items: flex-start;
  gap: 18px;
  display: inline-flex;
}

.frame .div-wrapper-3 {
  border: 1px solid #fff;
  border-radius: 8px;
  align-items: center;
  gap: 10px;
  padding: 10px 10px 10px 18px;
  display: flex;
}

.frame .text-wrapper-16 {
  color: #fff;
  letter-spacing: 0;
  width: 84px;
  margin-top: -1px;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
}

.frame .div-wrapper-4 {
  border: 1px solid #fff;
  border-radius: 8px;
  align-items: flex-start;
  gap: 10px;
  height: 112px;
  padding: 17px 10px 10px 18px;
  display: flex;
}

.frame .div-wrapper-5 {
  background-color: #160ac1;
  border: 1px solid;
  border-radius: 52px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  height: 46px;
  padding: 17px 24px 20px;
  display: flex;
}

.frame .text-wrapper-17 {
  color: #fff;
  letter-spacing: 0;
  margin-top: -7.5px;
  margin-bottom: -5.5px;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}

.frame .div-13 {
  background-color: #fff;
  justify-content: space-between;
  gap: 137px;
  padding: 32px 55px 32px 45px;
  display: flex;
  top: 0;
  left: 160px;
  box-shadow: 0 4px 52px #0000001f;
}

.frame .div-14 {
  text-align: left;
  align-items: flex-start;
  gap: 16px;
}

.frame .img-2 {
  height: 48px;
}

.frame .we-re-on-call {
  color: #12222c;
  letter-spacing: 0;
  margin-top: -1px;
  font-family: Raleway, Helvetica;
  font-size: 48px;
  font-weight: 600;
  line-height: 52px;
}

.frame .text-wrapper-18 {
  color: #5f6e89;
  letter-spacing: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
}

.frame .frame-wrapper-3 {
  background-image: url("image-8.9e9df17c.png");
  background-position: 50%;
  background-size: cover;
  width: 400px;
}

.frame .div-wrapper-6 {
  background-color: #160ac1;
  border: 1px solid;
  border-radius: 52px;
  width: 220px;
  margin-top: 271px;
  padding: 17px 24px;
}

.frame .overlap-wrapper {
  width: 100%;
  height: 600px;
  top: 1517px;
  left: 0;
}

.frame .overlap-2 {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.frame .image-2 {
  max-width: 100%;
  height: auto;
}

.frame .welcome-to-anderson {
  margin-bottom: 10px;
  font-size: 24px;
}

.frame .your-trusted-partner {
  max-width: 600px;
  font-size: 16px;
}

.frame .text-wrapper-19 {
  color: #fff;
  cursor: pointer;
  background-color: #007bff;
  border-radius: 5px;
  margin-top: 20px;
  padding: 10px 20px;
}

@media (width >= 768px) {
  .frame .overlap-2 {
    text-align: left;
    flex-direction: row;
    align-items: flex-start;
  }

  .frame .image-2 {
    max-width: none;
  }

  .frame .frame-wrapper-4 {
    padding: 0 20px;
  }

  .frame .welcome-to-anderson {
    font-size: 32px;
  }

  .frame .your-trusted-partner {
    max-width: 100%;
    font-size: 18px;
  }

  .frame .text-wrapper-19 {
    margin-top: 0;
  }
}

.frame .image-2 {
  object-fit: cover;
  height: 600px;
  top: 0;
  left: 0;
}

.frame .frame-wrapper-4 {
  background-color: #010048;
  height: 600px;
  top: 0;
  left: 867px;
}

.frame .div-15 {
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  display: inline-flex;
  top: 93px;
  left: 48px;
}

.frame .welcome-to-anderson {
  color: #fff;
  letter-spacing: 0;
  margin-top: -1px;
  font-family: Raleway, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
}

.frame .your-trusted-partner {
  color: #fff;
  letter-spacing: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
}

.frame .div-wrapper-7 {
  background-color: #010048;
  border: 1px solid #fff;
  border-radius: 52px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  height: 46px;
  padding: 17px 24px;
  display: flex;
}

.frame .services {
  flex: none;
  gap: 40px;
}

.frame .title-to-center {
  text-align: center;
  gap: 15px;
  margin: auto;
}

.frame .text-wrapper-20 {
  color: #12222c;
  letter-spacing: 0;
  white-space: nowrap;
  margin-top: -1px;
  font-family: Raleway, Helvetica;
  font-size: 40px;
  font-weight: 700;
  line-height: 44px;
}

.frame .rectangle {
  background-color: #090088;
  width: 101px;
  height: 9px;
  margin: auto;
}

.frame .services-scroll {
  justify-content: center;
  padding: 50px;
  display: flex;
}

.frame .left-arrow {
  width: 40px !important;
  height: 40px !important;
}

.frame .div-22 {
  justify-content: center;
  gap: 20px;
  display: flex;
}

.frame .div-23 {
  border: 1px solid #cdcdcd;
  border-radius: 12px;
  width: 274px;
  height: 353px;
  overflow: hidden;
}

.frame .div-24 {
  background-image: url("frame-83.3b4e28b2.png");
  background-position: 50%;
  background-size: cover;
  height: 203px;
  top: -6px;
  left: 0;
}

.frame .frame-wrapper-5 {
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  display: flex;
  top: 209px;
  left: 12px;
}

.frame .div-25 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
}

.frame .text-wrapper-21 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  margin-top: -1px;
  font-family: Raleway, Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
}

.frame .text-wrapper-22 {
  color: #5f6e89;
  letter-spacing: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.frame .div-26 {
  background-image: url("frame-83-1.56537d44.png");
  background-position: 50%;
  background-size: cover;
  height: 203px;
  top: -6px;
  left: 0;
}

.frame .div-27 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 4px;
  margin-right: -6px;
  display: inline-flex;
}

.frame .text-wrapper-23 {
  color: #5f6e89;
  letter-spacing: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.frame .div-28 {
  background-image: url("frame-83-2.d8ba1e3d.png");
  background-position: 50%;
  background-size: cover;
  width: 274px;
  height: 203px;
  top: -6px;
  left: 0;
}

.frame .div-29 {
  background-image: url("frame-83-3.bfc418a7.png");
  background-position: 50%;
  background-size: cover;
  height: 203px;
  top: -6px;
  left: 0;
}

.frame .view-all-wrapper {
  justify-content: center;
  gap: 2px;
  display: flex;
}

.frame .text-wrapper-24 {
  color: #160ac1;
  letter-spacing: 0;
  white-space: nowrap;
  height: 19px;
  margin-top: -.5px;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}

.frame .right-arrow {
  height: 20px !important;
}

.frame .div-31 {
  background-image: url("frame-83-4.4a4ff950.png");
  background-position: 50%;
  background-size: cover;
  height: 203px;
  top: -6px;
  left: 0;
}

.frame .div-32 {
  background-image: url("frame-83-5.ceecf0f5.png");
  background-position: 50%;
  background-size: cover;
  height: 203px;
  top: -6px;
  left: 0;
}

.frame .div-33 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 4px;
  margin-right: -43px;
  display: inline-flex;
}

.frame .window-door {
  color: #000;
  letter-spacing: 0;
  margin-top: -1px;
  font-family: Raleway, Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

.frame .div-34 {
  background-image: url("frame-83-6.6108b405.png");
  background-position: 50%;
  background-size: cover;
  height: 203px;
  top: -6px;
  left: 0;
}

.frame .div-35 {
  background-image: url("frame-83-7.989a8663.png");
  background-position: 50%;
  background-size: cover;
  height: 203px;
  top: -6px;
  left: 0;
}

.div-4 {
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  display: flex;
}

.div-5 {
  box-sizing: border-box;
  width: 100%;
  max-width: 600px;
  padding: 20px;
}

.div-6 {
  text-align: center;
}

.img {
  max-width: 100%;
  height: auto;
}

.text-wrapper-13 {
  font-size: 16px;
}

.div-7 {
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
}

.div-8 {
  text-align: center;
  flex: 1;
  max-width: calc(50% - 10px);
  margin-bottom: 10px;
  padding: 10px;
}

.text-wrapper-14 {
  font-size: 16px;
  font-weight: bold;
}

.text-wrapper-15 {
  font-size: 14px;
}

.div-9 {
  box-sizing: border-box;
  background-color: #f0f0f0;
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
  padding: 20px;
}

.div-10 {
  text-align: center;
}

.get-a-quote-and-make {
  font-size: 16px;
}

.div-11 {
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
}

.div-wrapper-2, .div-wrapper-3, .div-wrapper-4 {
  flex: 1;
  max-width: calc(33.33% - 10px);
  padding: 10px;
}

.text-wrapper-14, .text-wrapper-16 {
  font-size: 14px;
  font-weight: bold;
}

.div-wrapper-5 {
  text-align: center;
}

.text-wrapper-17 {
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
}

.div-14 {
  max-width: 100%;
}

.img-2 {
  max-width: 100%;
  height: auto;
}

.text-container {
  text-align: center;
  flex: 1;
  padding: 20px;
}

.we-re-on-call {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
}

.text-wrapper-18 {
  max-width: 100%;
  margin-bottom: 10px;
  font-size: 16px;
}

.div-wrapper-6 {
  color: #fff;
  cursor: pointer;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
}

@media (width <= 768px) {
  .div-13 {
    text-align: center;
    flex-direction: column;
  }

  .text-container {
    padding: 10px;
  }

  .we-re-on-call {
    font-size: 24px;
  }

  .text-wrapper-18 {
    font-size: 18px;
  }
}

.bb-footer {
  color: #fff;
  background: #2f2f2f;
}

.footer-links > div {
  font-size: 18px;
}

.bb-footer .contact {
  margin: 20px 20px 0;
}

.bb-footer .contact-links > div {
  margin: 10px;
}

.bb-footer .contact-links {
  margin: 45px;
}

.bb-footer .bg-black > div {
  margin: 40px 20px 20px;
}

.footer-top {
  color: #000;
  box-shadow: 0 0 11px gray;
}

.book-a-call {
  background-color: #fafcfe;
  background-image: linear-gradient(#fff 50%, #2f2f2f 50%);
  margin-bottom: -48px;
}

.page-navigation {
  justify-content: end;
  display: flex;
}

/*# sourceMappingURL=index.62d8955f.css.map */

:root {
    --main-website: rgba(9, 0, 136, 1);
    --text-4: rgba(205, 213, 224, 1);
    --website-black: rgba(0, 0, 0, 1);
    --website-blue: rgba(76, 101, 253, 1);
    --website-yellow: rgba(255, 212, 62, 1);
}

html {
    scroll-behavior: smooth;
    max-width: 100%;
    margin: 0 auto !important; /* Center the content horizontally */
}
body {
    /* background-color: #fafcfe; */
    max-width: 1440px;
    margin: 0 auto; /* Center the content horizontally */
    font-family: 'Nunito', Helvetica;
}
section {
    font-family: 'Nunito', Helvetica; /* min-height: 80vh; */
    margin: 40px 0;
    background-color: #fafcfe;
}

/*

reusable components

*/

.btn {
    --bs-btn-border-radius: 50px;
    --bs-btn-padding-x: 3rem;
}
.btn-primary {
    --bs-btn-bg: #160ac2;
    --bs-btn-border-color: #160ac2;
}

.btn-outline-primary {
    --bs-btn-border-color: #160ac2;
    --bs-btn-hover-bg: #160ac2;
    --bs-btn-color: #160ac2;
    --bs-btn-hover-border-color: #160ac2;
}

.pointer {
    cursor: pointer;
}

.df-center {
    display: flex !important ;
    justify-content: center;
}

.landing-page-image {
    height: 340px;
}

.landing-page-header {
    font-size: 48px;
    margin-left: -77px;
    text-shadow: 2px 2px 4px #000000;
    top: 50%;
    left: 50%;
}

.frame {
    background-color: #fafcfe;
}

.frame .component-3 {
    margin: auto;
    /* display: flex;
justify-content: space-around; */
    left: 64px !important;
    /* position: absolute !important; */
    top: 144px !important;
}

.frame .component-2 {
    display: flex;
    justify-content: center;
    margin-bottom: 150px;
}

.frame .component-instance {
    background-image: url(../../static/img/frame-91-2.svg) !important;
}

.frame .overlap {
    left: 0;
    /* position: absolute; */
    top: 3655px;
    width: 100%;
}

.frame .frame-wrapper-2 {
    background-color: #2e2e2e;
    left: 0;

    text-align: center;
}
.frame-wrapper-2 .div-4 {
    display: flex;
    justify-content: space-around;
    margin: auto;
}

.frame .div-4 {
    height: 522px;
    left: 154px;
    /* position: relative; */
    top: 236px;
    /* width: 1184px; */
    padding-top: 40px;
}

.frame .div-5 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 56px;
    left: 0;
    /* position: absolute; */
    top: 47px;
}

.frame .div-6 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 30px;
    /* position: relative; */
}

.frame .img {
    height: 53.03px;
    /* position: relative; */
    /* width: 87px; */
}

.frame .text-wrapper-13 {
    color: #ffffff;

    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 26px;
    /* position: relative; */
    /* width: 349px; */
}

.frame .div-7 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 48px;
    /* position: relative; */
}

.frame .div-8 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    /* position: relative; */
}

.frame .text-wrapper-14 {
    color: #ffffff;

    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 26px;
    margin-top: -1px;
    /* position: relative; */
    white-space: nowrap;
    width: fit-content;
}

.frame .text-wrapper-15 {
    color: #ffffff;

    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 26px;
    /* position: relative; */
    white-space: nowrap;
    /* width: fit-content; */
}

.frame .div-9 {
    align-items: flex-start;
    background-color: #1c1c1c;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    left: 648px;
    padding: 56px 0px 40px 24px;
    /* position: absolute; */
    top: 0;
    /* width: 536px; */
}

.frame .div-10 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 40px;
    /* position: relative; */
}

.frame .get-a-quote-and-make {
    color: #ffffff;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 36px;
    margin-top: 40px;
    /* position: relative; */
    /* width: fit-content; */
}

.frame .div-11 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    /* position: relative; */
}

.frame .div-wrapper-2 {
    align-items: center;
    border: 1px solid;
    border-color: #ffffff;
    border-radius: 8px;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    padding: 10px 10px 10px 18px;
    /* position: relative; */
    /* width: 476px; */
}

.frame .div-12 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 18px;
    /* position: relative; */
}

.frame .div-wrapper-3 {
    align-items: center;
    border: 1px solid;
    border-color: #ffffff;
    border-radius: 8px;
    display: flex;
    gap: 10px;
    padding: 10px 10px 10px 18px;
    /* position: relative; */
    /* width: 229px; */
}

.frame .text-wrapper-16 {
    color: #ffffff;

    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 26px;
    margin-top: -1px;
    /* position: relative; */
    width: 84px;
}

.frame .div-wrapper-4 {
    align-items: flex-start;
    border: 1px solid;
    border-color: #ffffff;
    border-radius: 8px;
    display: flex;
    gap: 10px;
    height: 112px;
    padding: 17px 10px 10px 18px;
    /* position: relative; */
    /* width: 476px; */
}

.frame .div-wrapper-5 {
    align-items: center;
    background-color: #160ac1;
    border: 1px solid;
    border-radius: 52px;
    display: flex;
    gap: 4px;
    height: 46px;
    justify-content: center;
    padding: 17px 24px 20px;
    /* position: relative; */
    /* width: 165px; */
}

.frame .text-wrapper-17 {
    color: #ffffff;

    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-bottom: -5.5px;
    margin-top: -7.5px;
    /* position: relative; */
    /* width: fit-content; */
}

.frame .div-13 {
    justify-content: space-between;
    background-color: #ffffff;
    box-shadow: 0px 4px 52px #0000001f;
    display: flex;
    gap: 137px;
    left: 160px;
    padding: 32px 55px 32px 45px;
    /* position: absolute; */
    top: 0;
}

.frame .div-14 {
    align-items: flex-start;

    gap: 16px;
    text-align: left;
    /* position: relative; */
}

.frame .img-2 {
    height: 48px;
    /* position: relative; */
    /* width: 48px; */
}

.frame .we-re-on-call {
    color: #12222c;
    font-family: 'Raleway', Helvetica;
    font-size: 48px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 52px;
    margin-top: -1px;
    /* position: relative; */
    /* width: fit-content; */
}

.frame .text-wrapper-18 {
    color: #5f6e89;

    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 26px;
    /* position: relative; */
    /* width: 535px; */
}

.frame .frame-wrapper-3 {
    background-image: url(../../static/img/image-8.png);
    background-position: 50% 50%;
    background-size: cover;
    width: 400px;
    /* position: relative; */
    /* width: 398px; */
}

.frame .div-wrapper-6 {
    background-color: #160ac1;
    border: 1px solid;
    border-radius: 52px;
    width: 220px;
    padding: 17px 24px;
    margin-top: 271px;
}

.frame .overlap-wrapper {
    height: 600px;
    left: 0;
    /* position: absolute; */
    top: 1517px;
    width: 100%;
}

/* Default styles for the section */
.frame .overlap-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.frame .image-2 {
    max-width: 100%;
    height: auto;
}

.frame .welcome-to-anderson {
    font-size: 24px;
    margin-bottom: 10px;
}

.frame .your-trusted-partner {
    font-size: 16px;
    max-width: 600px;
}

.frame .text-wrapper-19 {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

/* Media query for responsive design */
@media (min-width: 768px) {
    .frame .overlap-2 {
        flex-direction: row;
        align-items: flex-start;
        text-align: left;
    }

    .frame .image-2 {
        /* width: 50%; */
        max-width: none;
    }

    .frame .frame-wrapper-4 {
        /* width: 50%; */
        padding: 0 20px;
    }

    .frame .welcome-to-anderson {
        font-size: 32px;
    }

    .frame .your-trusted-partner {
        font-size: 18px;
        max-width: 100%;
    }

    .frame .text-wrapper-19 {
        margin-top: 0;
    }
}

.frame .image-2 {
    height: 600px;
    left: 0;
    object-fit: cover;
    /* position: absolute; */
    top: 0;
    /* width: 900px; */
}

.frame .frame-wrapper-4 {
    background-color: #010048;
    height: 600px;
    left: 867px;
    /* position: absolute; */
    top: 0;
    /* width: 573px; */
}

.frame .div-15 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 48px;
    left: 48px;
    /* position: relative; */
    top: 93px;
}

.frame .welcome-to-anderson {
    color: #ffffff;
    font-family: 'Raleway', Helvetica;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 44px;
    margin-top: -1px;
    /* position: relative; */
    /* width: 499px; */
}

.frame .your-trusted-partner {
    color: #ffffff;

    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 26px;
    /* position: relative; */
    /* width: 417px; */
}

.frame .div-wrapper-7 {
    align-items: center;
    background-color: #010048;
    border: 1px solid;
    border-color: #ffffff;
    border-radius: 52px;
    display: flex;
    gap: 4px;
    height: 46px;
    justify-content: center;
    padding: 17px 24px;
    /* position: relative; */
    /* width: 160px; */
}

.frame .services {
    /* align-items: center; */
    flex: 0 0 auto;
    /* flex-direction: column; */
    gap: 40px;
    /* position: relative; */
}

.frame .title-to-center {
    text-align: center;
    margin: auto;
    /* flex: 0 0 auto; */
    /* flex-direction: column; */
    gap: 15px;
    /* position: relative; */
}

.frame .text-wrapper-20 {
    color: #12222c;
    font-family: 'Raleway', Helvetica;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 44px;
    margin-top: -1px;
    /* position: relative; */
    white-space: nowrap;
}

.frame .rectangle {
    background-color: #090088;
    height: 9px;
    /* position: relative; */
    width: 101px;
    margin: auto;
}

.frame .services-scroll {
    display: flex;
    justify-content: center;
    padding: 50px;
    /* position: relative; */
}

.frame .left-arrow {
    height: 40px !important;
    /* position: relative !important; */
    width: 40px !important;
}

.frame .div-22 {
    display: flex;
    justify-content: center;
    gap: 20px;
    /* position: relative; */
}

.frame .div-23 {
    border: 1px solid;
    border-color: #cdcdcd;
    border-radius: 12px;
    height: 353px;
    overflow: hidden;
    /* position: relative; */
    width: 274px;
}

.frame .div-24 {
    background-image: url(../../static/img/frame-83.png);
    background-position: 50% 50%;
    background-size: cover;
    height: 203px;
    left: 0;
    /* position: absolute; */
    top: -6px;
    /* width: 274px; */
}

.frame .frame-wrapper-5 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 14px;
    left: 12px;
    /* position: absolute; */
    top: 209px;
    /* width: 245px; */
}

.frame .div-25 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 4px;
    /* position: relative; */
}

.frame .text-wrapper-21 {
    color: #000000;
    font-family: 'Raleway', Helvetica;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 32px;
    margin-top: -1px;
    /* position: relative; */
    white-space: nowrap;
    /* width: fit-content; */
}

.frame .text-wrapper-22 {
    color: #5f6e89;

    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
    /* position: relative; */
    /* width: 241px; */
}

.frame .div-26 {
    background-image: url(../../static/img/frame-83-1.png);
    background-position: 50% 50%;
    background-size: cover;
    height: 203px;
    left: 0;
    /* position: absolute; */
    top: -6px;
    /* width: 274px; */
}

.frame .div-27 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 4px;
    margin-right: -6px;
    /* position: relative; */
}

.frame .text-wrapper-23 {
    color: #5f6e89;

    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
    /* position: relative; */
    /* width: 251px; */
}

.frame .div-28 {
    background-image: url(../../static/img/frame-83-2.png);
    background-position: 50% 50%;
    background-size: cover;
    height: 203px;
    left: 0;
    /* position: absolute; */
    top: -6px;
    width: 274px;
}

.frame .div-29 {
    background-image: url(../../static/img/frame-83-3.png);
    background-position: 50% 50%;
    background-size: cover;
    height: 203px;
    left: 0;
    /* position: absolute; */
    top: -6px;
    /* width: 274px; */
}

.frame .view-all-wrapper {
    display: flex;
    justify-content: center;
    gap: 2px;
    /* position: relative; */
}

.frame .text-wrapper-24 {
    color: #160ac1;

    font-size: 16px;
    font-weight: 600;
    height: 19px;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.5px;
    /* position: relative; */
    white-space: nowrap;
    /* width: 77px; */
}

.frame .right-arrow {
    height: 20px !important;
    /* position: relative !important; */
    /* width: 20px !important; */
}

.frame .div-31 {
    background-image: url(../../static/img/frame-83-4.png);
    background-position: 50% 50%;
    background-size: cover;
    height: 203px;
    left: 0;
    /* position: absolute; */
    top: -6px;
    /* width: 274px; */
}

.frame .div-32 {
    background-image: url(../../static/img/frame-83-5.png);
    background-position: 50% 50%;
    background-size: cover;
    height: 203px;
    left: 0;
    /* position: absolute; */
    top: -6px;
    /* width: 274px; */
}

.frame .div-33 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 4px;
    margin-right: -43px;
    /* position: relative; */
}

.frame .window-door {
    color: #000000;
    font-family: 'Raleway', Helvetica;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -1px;
    /* position: relative; */
    /* width: 288px; */
}

.frame .div-34 {
    background-image: url(../../static/img/frame-83-6.png);
    background-position: 50% 50%;
    background-size: cover;
    height: 203px;
    left: 0;
    /* position: absolute; */
    top: -6px;
    /* width: 274px; */
}

.frame .div-35 {
    background-image: url(../../static/img/frame-83-7.png);
    background-position: 50% 50%;
    background-size: cover;
    height: 203px;
    left: 0;
    /* position: absolute; */
    top: -6px;
    /* width: 274px; */
}

/* Default styles for the content */
.div-4 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
}

.div-5 {
    width: 100%;
    max-width: 600px; /* Adjust the maximum width as needed */
    box-sizing: border-box;
    padding: 20px;
    /* background-color: #f0f0f0;  */
    /* Background color for left content */
}

.div-6 {
    text-align: center;
}

.img {
    max-width: 100%;
    height: auto;
}

.text-wrapper-13 {
    font-size: 16px;
}

.div-7 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.div-8 {
    flex: 1;
    max-width: calc(50% - 10px); /* 50% width with spacing */
    padding: 10px;
    /* background-color: #e0e0e0;  */
    /* Background color for link container */
    margin-bottom: 10px;
    text-align: center;
}

.text-wrapper-14 {
    font-size: 16px;
    font-weight: bold;
}

.text-wrapper-15 {
    font-size: 14px;
}

.div-9 {
    width: 100%;
    max-width: 600px; /* Adjust the maximum width as needed */
    box-sizing: border-box;
    padding: 20px;
    background-color: #f0f0f0; /* Background color for right content */
    margin-top: 20px; /* Spacing between left and right content */
}

.div-10 {
    text-align: center;
}

.get-a-quote-and-make {
    font-size: 16px;
}

.div-11 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.div-wrapper-2,
.div-wrapper-3,
.div-wrapper-4 {
    flex: 1;
    max-width: calc(33.33% - 10px); /* 33.33% width with spacing */
    padding: 10px;
}

.text-wrapper-14,
.text-wrapper-16 {
    font-size: 14px;
    font-weight: bold;
}

.div-wrapper-5 {
    text-align: center;
}

.text-wrapper-17 {
    font-size: 16px;
    /* background-color: #007bff; Background color for "Get in Touch" */
    /* color: white; */
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

/* for contacut us */

/* Default styles for the content */
/* Default styles for the content */

.div-14 {
    max-width: 100%;
}

.img-2 {
    max-width: 100%;
    height: auto;
}

.text-container {
    flex: 1;
    padding: 20px;
    text-align: center;
}

.we-re-on-call {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.text-wrapper-18 {
    font-size: 16px;
    max-width: 100%;
    margin-bottom: 10px;
}

.div-wrapper-6 {
    font-size: 16px;
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

/* Media query for responsive design */
@media (max-width: 768px) {
    .div-13 {
        flex-direction: column;
        text-align: center;
    }

    .text-container {
        padding: 10px;
    }

    .we-re-on-call {
        font-size: 24px;
    }

    .text-wrapper-18 {
        font-size: 18px;
    }
}

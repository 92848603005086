.property-years-wrapper {
  background-color: #ffffff;
  box-shadow: 0px 17px 12px #00000021;
  height: 219px;
  width: 228px;
}

.property-years-wrapper .frame-14 {
  display: inline-flex;
  flex-direction: column;
  gap: 2px;
  position: relative;
}

.property-years-wrapper .element {
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.property-years-wrapper .years-experience {
  letter-spacing: 0;
  position: relative;
  width: fit-content;
}

.property-years-wrapper .years {
  align-items: flex-start;
  left: 31px;
  top: 26px;
}

.property-years-wrapper .since {
  align-items: center;
  left: 27px;
  top: 53px;
}

.property-years-wrapper .frame-14.years .element {
  color: var(--main-website);
  font-family: "Raleway", Helvetica;
  font-size: 110px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
}

.property-years-wrapper .frame-14.since .element {
  color: #5f6e89;
  font-family: "Nunito", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 2.88px;
  line-height: 26px;
}

.property-years-wrapper .frame-14.years .years-experience {
  color: #5f6e89;
  font-family: "Nunito", Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  white-space: nowrap;
}

.property-years-wrapper .frame-14.since .years-experience {
  color: var(--main-website);
  font-family: "Inter", Helvetica;
  font-size: 70px;
  font-weight: 700;
  line-height: normal;
}

.bb-footer {
    background: #2f2f2f;
}

.bb-footer {
    color: white;
}
.footer-links > div {
    font-size: 18px;
}

.bb-footer .contact {
    margin: 20px 20px 0;
}
.bb-footer .contact-links > div {
    margin: 10px;
}
.bb-footer .contact-links {
    margin: 45px;
}
.bb-footer .bg-black > div {
    margin: 40px 20px 20px;
}

.footer-top {
    box-shadow: 0px 0px 11px 0px gray;
    color: #000;
}

.book-a-call {
    background-image: linear-gradient(180deg, white 50%, #2f2f2f 50%);
    margin-bottom: -48px;
    background-color: #fafcfe;
}

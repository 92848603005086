.company-section-wrapper {
    background-color: #ffffff;
    border-radius: 16px;
    height: 566px;
    overflow: hidden;
}

.company-section-wrapper .overlap-group {
    /* background-image: url(../../../static/img/image-9-1.png); */
    background-size: cover;
    height: 100%;
}

.company-section-wrapper .blog-card {
    border-radius: 16px;
    height: 231px;
    left: 72px;
    position: relative;
    top: 187px;
    width: 393px;
}

.company-section-wrapper .text-box-outer {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
    top: 32px;
}

.company-section-wrapper .products-services {
    color: #ffffff;
    font-family: 'Raleway', Helvetica;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 48px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    text-shadow: 0px 4px 12px #0000001f;
    width: fit-content;
}

.company-section-wrapper .company-button {
    align-items: center;
    background-color: #160ac1;
    border-radius: 52px;
    display: flex;
    gap: 4px;
    height: 46px;
    justify-content: center;
    padding: 17px 24px;
    position: relative;
    width: 160px;
    border: 0;
}

.company-section-wrapper .company-button-text {
    color: #ffffff;
    font-family: 'Nunito', Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-bottom: -4px;
    margin-top: -6px;
    position: relative;
    width: fit-content;
}

.company-section-wrapper .blog-card-bg {
    background-color: #00000070;
}

.company-section-wrapper .blog-card-transperency {
    -webkit-backdrop-filter: blur(18px) brightness(100%);
    backdrop-filter: blur(18px) brightness(100%);
    background: linear-gradient(
        180deg,
        rgb(73.31, 73.31, 73.31) 0%,
        rgba(0, 0, 0, 0.48) 100%
    );
    background-blend-mode: soft-light;
    border: 1.5px solid;
    border-color: transparent;
    border-image: radial-gradient(rgb(255, 193, 0), rgba(255, 193, 0, 0)) 1;
    box-shadow: 2px 4px 30px #00000014;
}

.property-check-wrapper {
    justify-content: space-evenly;
    display: flex;
    gap: 86px;
    /* position: relative; */
}

.property-check-wrapper .overlap-group-wrapper {
    background-position: 50% 50%;
    background-size: cover;
    height: 618px;
    position: relative;
    width: 489px;
}

.property-check-wrapper .overlap-group-2 {
    position: relative;
}

.property-check-wrapper .image {
    height: 617px;
    left: 0;
    object-fit: cover;
    position: relative;
    top: 0;
    width: 489px;
}

.property-check-wrapper .component-1 {
    position: absolute !important;
    right: 0;
    bottom: 0;
}

.property-check-wrapper .frame-15 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 48px;
    position: relative;
}

.property-check-wrapper .frame-16 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    position: relative;
}

.property-check-wrapper .p {
    color: #12222c;
    font-family: 'Raleway', Helvetica;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 44px;
    margin-top: -1px;
    position: relative;
    width: 499px;
}

.property-check-wrapper .text-wrapper-8 {
    color: #081f58;
    font-family: 'Nunito', Helvetica;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 26px;
    position: relative;
    width: 533px;
}

.property-check-wrapper .features-wrapper {
    gap: 28px;
    position: relative;
}

.property-check-wrapper .frame-18 {
    gap: 16px;
    position: relative;
}

.property-check-wrapper .frame-19 {
    position: relative;
}

.property-check-wrapper .approve-tick {
    height: 24px !important;
    left: 3px !important;
    /* position: absolute !important; */
    top: 3px !important;
    width: 24px !important;
}

.property-check-wrapper .text-wrapper-9 {
    color: #12222c;
    font-family: 'Raleway', Helvetica;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 26px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.property-check-wrapper .frame-20 {
    display: inline-flex;
    flex: 0 0 auto;
    gap: 16px;
    position: relative;
}

.property-check-wrapper .frame-21 {
    position: relative;
}

.property-check-wrapper .text-wrapper-10 {
    color: #12222c;
    font-family: 'Raleway', Helvetica;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 26px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.property-check-wrapper .frame-22 {
    display: inline-flex;
    flex: 0 0 auto;
    gap: 16px;
    position: relative;
}

.property-check-wrapper .frame-23 {
    position: relative;
}

.property-check-wrapper .text-wrapper-11 {
    color: #12222c;
    font-family: 'Raleway', Helvetica;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 26px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.property-check-wrapper .frame-24 {
    display: inline-flex;
    flex: 0 0 auto;
    gap: 16px;
    position: relative;
}

.property-check-wrapper .frame-25 {
    position: relative;
}

.property-check-wrapper .text-wrapper-12 {
    color: #12222c;
    font-family: 'Raleway', Helvetica;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 26px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.property-check-wrapper .check-marks {
    background-image: url(../../../static/img/frame-91.svg);
}

.property-check-wrapper .bullet {
    background-image: url(../../../static/img/frame-91-1.svg);
}

.property-check-wrapper .property-1-check-marks {
    align-items: flex-end;
}

.property-check-wrapper .property-1-bullet {
    align-items: center;
    display: flex;
    margin: 20px 5px;
}

.property-check-wrapper .property-1-2-check-marks {
    align-items: flex-end;
}

.property-check-wrapper .property-1-2-bullet {
    align-items: center;
}

.property-check-wrapper .property-1-5-check-marks {
    align-items: flex-end;
}

.property-check-wrapper .property-1-5-bullet {
    align-items: center;
}

.property-check-wrapper .property-1-8-check-marks {
    align-items: flex-end;
}

.property-check-wrapper .property-1-8-bullet {
    align-items: center;
}

.property-check-wrapper .frame-18.property-1-check-marks .frame-19 {
    background-color: #12222c;
    height: 30px;
    width: 30px;
}

.property-check-wrapper .frame-18.property-1-bullet .frame-19 {
    background-color: #090088;
    border-radius: 8px;
    height: 16px;
    width: 16px;
}

.property-check-wrapper .frame-18.property-1-bullet .text-wrapper-9 {
    margin-top: -1px;
}

.property-check-wrapper .frame-20.property-1-2-check-marks .frame-21 {
    background-color: #12222c;
    height: 30px;
    width: 30px;
}

.property-check-wrapper .frame-20.property-1-2-bullet .frame-21 {
    background-color: #090088;
    border-radius: 8px;
    height: 16px;
    width: 16px;
}

.property-check-wrapper .frame-20.property-1-2-bullet .text-wrapper-10 {
    margin-top: -1px;
}

.property-check-wrapper .frame-22.property-1-5-check-marks .frame-23 {
    background-color: #12222c;
    height: 30px;
    width: 30px;
}

.property-check-wrapper .frame-22.property-1-5-bullet .frame-23 {
    background-color: #090088;
    border-radius: 8px;
    height: 16px;
    width: 16px;
}

.property-check-wrapper .frame-22.property-1-5-bullet .text-wrapper-11 {
    margin-top: -1px;
}

.property-check-wrapper .frame-24.property-1-8-check-marks .frame-25 {
    background-color: #12222c;
    height: 30px;
    width: 30px;
}

.property-check-wrapper .frame-24.property-1-8-bullet .frame-25 {
    background-color: #090088;
    border-radius: 8px;
    height: 16px;
    width: 16px;
}

.property-check-wrapper .frame-24.property-1-8-bullet .text-wrapper-12 {
    margin-top: -1px;
}

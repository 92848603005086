.bb-header {
    /* position: relative; */
}

.bb-header .frame-wrapper {
    background-color: #010048;
    height: 42px;
    width: 100%;
}

.bb-header .banner {
    justify-content: space-evenly;
    display: flex;
    margin: auto;
    padding-top: 7px;
    /* gap: 72px; */
}

.bb-header .frame-2 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    /* position: relative; */
}

.bb-header .instance-node {
    height: 24px !important;
    /* position: relative !important; */
    width: 24px !important;
}

.bb-header .text-wrapper {
    color: #ffffff;

    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    /* position: relative; */
    width: fit-content;
}

.bb-header .frame-3 {
    align-items: center;
    height: 54px;
}

.bb-header .frame-4 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    /* position: relative; */
}

.bb-header .mask-group,
.bb-footer .mask-group {
    height: 53.03px;
    /* position: relative; */
    width: 87px;
}

.bb-header .frame-5 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 48px;
    /* position: relative; */
}

.bb-header .frame-6 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 2px;
    /* position: relative; */
}
.bb-header .frame-6 a {
    text-decoration: none;
}

.bb-header .text-wrapper-2 {
    color: #12222c;

    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    /* position: relative; */
    width: fit-content;
}

.bb-header .frame-7 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    /* position: relative; */
}

.bb-header .div-wrapper {
    align-items: flex-start;
    background-color: #ffffff;
    border: 1px solid;
    border-color: var(--text-4);
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 46px;
    justify-content: center;
    padding: 16px 8px 16px 24px;
    /* position: relative; */
    width: 179px;
}

.bb-header .frame-8 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 12px;
    margin-bottom: -5px;
    margin-top: -5px;
    /* position: relative; */
}

.bb-header .search-input {
    color: #12222c;

    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    /* position: relative; */
    width: 115px;
    border: 0;
}

.bb-header .frame-9 {
    display: inline-flex;
    flex: 0 0 auto;
    /* position: relative; */
}

.bb-header .frame-10 {
    align-items: center;
    background-color: #ffffff;
    border-radius: 52px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    height: 46px;
    justify-content: center;
    padding: 17px 24px;
    /* position: relative; */
}

.bb-header .text-wrapper-4 {
    color: #160ac1;

    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-bottom: -4px;
    margin-top: -6px;
    /* position: relative; */
    width: fit-content;
}

.bb-header .frame-11 {
    align-items: center;
    background-color: #160ac1;
    border: 1px solid;
    border-color: #160ac1;
    border-radius: 52px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    height: 46px;
    justify-content: center;
    padding: 17px 24px;
    /* position: relative; */
}

.bb-header .text-wrapper-5 {
    color: #ffffff;

    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-bottom: -4px;
    margin-top: -6px;
    /* position: relative; */
    width: fit-content;
}

.bb-header .text-wrapper-6 {
    color: #081f58;

    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-bottom: -4px;
    margin-top: -6px;
    /* position: relative; */
    width: fit-content;
}

.bb-header.get-quote {
    align-items: center;
    background-color: #ffffff;
    display: inline-flex;
    flex-direction: column;
    gap: 24px;
    height: 149px;
}

.bb-header.sign-in {
    width: 100%;
}

.bb-header .nav-link.active {
    color: #160ac2;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
    border-bottom: 3px solid;
}

.bb-header.get-quote .frame-3 {
    display: inline-flex;
    gap: 130px;
    /* position: relative; */
}

.bb-header.sign-in .frame-3 {
    display: flex;
    justify-content: space-around;
    left: 110px;
    /* position: absolute; */
    top: 66px;
    /* width: 1266px; */
    margin: 10px 0;
}

.bb-header.get-quote .frame-4 {
    gap: 195px;
}

.bb-header.sign-in .frame-4 {
    gap: 275px;
}

.bb-header.get-quote .frame-7 {
    gap: 16px;
}

.bb-header.sign-in .frame-7 {
    gap: 24px;
}

.bb-header.get-quote .frame-9 {
    align-items: flex-start;
}

.bb-header.sign-in .frame-9 {
    align-items: center;
    background-color: #ffffff;
    border: 1px solid;
    border-color: #160ac1;
    border-radius: 52px;
    gap: 4px;
    height: 46px;
    justify-content: center;
    padding: 17px 24px;
}

.input-group-text {
    background-color: #fff;
}

.input-group
    > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
        .valid-feedback
    ):not(.invalid-tooltip):not(.invalid-feedback) {
    border-left: 0;
}
